// import { TRUE } from 'sass'
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: {
        getItem: key => localStorage.getItem(key),
        setItem: (key, value) => localStorage.setItem(key, value),
        removeItem: key => localStorage.removeItem(key)
      }
    })
  ],
  state: {
    bulkQuestionRes: [],
    levelChart: null,
    assesedChart:[],
    clusterChart: [],
    chartSearch: "",
    role: {},
    updateRole: null,
    userInfo: {
      name: "user",
      email: "dummyemail@gmail.com"
    },
    school:"Vibgyor Roots - PANATHUR",
    cluster:"Cluster 4",
    isNavBar:false,
    user: null,
    userType:localStorage.getItem('userType') || null,
    breadcrumb:"",
    selectedVideo: {},
    currentCraouselIndex: 0,
    userRoleId: '',
    
  },

  getters: {
    getRoleFromStore: state => state.role,
    currentCraouselIndex: state => state.currentCraouselIndex,
    getRoleId: state => state.userRoleId
  },
  mutations: {
    SET_ROLE(state, role){
      state.role = role
    },

    SET_ROLE_ID(state, roleId){
      state.userRoleId = roleId
    },
    SET_CURRENT_CRAOUSEL_INDEX(state, index) {
      state.currentCraouselIndex = index;
    },
    setChartSearch(state, value) {
      state.chartSearch = value;
    },
    setVideoDetails(state, payload){
      state.selectedVideo = payload
    },
    setUserType(state, type) {
      state.userType = type;
      localStorage.setItem('userType', type);
    }
    // other mutations...
  },
  actions: {
    setCurrentCraouselIndex({ commit }, index) {
      commit('SET_CURRENT_CRAOUSEL_INDEX', index);
    },
    saveUserType({ commit }, type) {
      commit('setUserType', type);
    },
    setRole({commit}, role){
      commit('SET_ROLE', role)
    },
    setRoleId({commit}, roleId){
      commit('SET_ROLE_ID', roleId)
    }
  },
  modules: {
  }
})
