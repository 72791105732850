<template>
  <div>
    <div fluid class="pad pb-0 px-8">
      <!-- Filter Dialog -->
    <v-dialog v-model="filterDialog" class="cdz" max-width="570px" style="z-index:1000 !important">
      <v-card width="570px" height="100vh" class="filterDialogClass">
        <v-row class="mt-4 mx-1">
          <v-col>
            <div class="pl-0 mb-2 text-body1 font-weight-bold grey--text">
              Filter
            </div>
          </v-col>
          <v-col class="d-flex justify-end">
            <div
              @click="clearFilter"
              class="text-body1 font-weight-bold black--text cursor"
            >
              CLEAR FILTER
            </div>
          </v-col>
        </v-row>
        <v-tabs vertical>   
          <div class="ml-3 mt-4 mr-4 filterLine">
            <v-tab key="stage" class="black--text justify-start" active-class="activeTab">
              Stage
              <span v-if="selectedStageFilter.length > 0" class="ml-1">
              ({{selectedStageFilter.length}})</span>
            </v-tab>
            <v-tab key="CoreSkillsSubject" class="black--text justify-start" active-class="activeTab">
              League
              <span v-if="selectedLeagueFilter.length > 0" class="ml-1">
                ({{ selectedLeagueFilter.length }})</span>
            </v-tab>
          </div>

          <v-tab-item key="stage">
            <v-card>
              <v-card-text>
                <v-chip-group v-model="selectedStageFilter" active-class="secondary" column :multiple="true">
                  <v-chip v-for="(stage,index) in stageOptions" 
                  :key="index"
                  :value="stage"
                  elevated>
                    {{stage}}
                  </v-chip>
                </v-chip-group>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item key="CoreSkillsSubject">
            <v-card>
              <v-card-text>
                <v-chip-group v-model="selectedLeagueFilter" active-class="secondary" column :multiple="true">
                  <v-chip v-for="(league,index) in leagueOptions" 
                  :key="index"
                  :value="league"
                  elevated>
                    {{league}}
                  </v-chip>
                </v-chip-group>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
        <div class="filterDialogButton">
          <v-card-actions class="px-6 pb-0">
            <v-spacer></v-spacer>
            <v-btn
              rounded
              outlined
              class="accent--text pa-4"
              @click="closeFilter"
              >Cancel</v-btn
            >
            <v-btn
              class="accent pa-4"
              :disabled="selectedStageFilter.length==0 && selectedLeagueFilter.length==0"
              @click="filterTeachers(selectedStageFilter,selectedLeagueFilter),getFilterCount()">Apply</v-btn
            >
          </v-card-actions>
        </div>
      </v-card>                
    </v-dialog>
  </div>
    <v-col>
      <v-card height="64px" width="100%"
        class="mx-4 pl-3 pt-5 pr-4 background elevation-0 d-flex flex-row justify-space-between align-center fixBar">
        <v-card-title class="pl-0 ml-0">
          <v-span @click="$router.push('/')"
            class="breadcrumb-text-unselected underline-on-hover cursor">Dashboard</v-span>

          <v-span><v-icon class="breadcrumb-arrow-unselected">mdi-chevron-right</v-icon></v-span>
          <v-menu offset-y class="menuZindex">
            <template v-slot:activator="{ on }">
              <v-span v-on="on" class="breadcrumb-text">
                Campaign Result
                <v-icon class="breadcrumb-arrow cursor">mdi-chevron-down</v-icon>
              </v-span>
            </template>

            <v-list>
              <v-list-item @click="$router.push('/teachersappraisal')">No. of teachers to be Appraised</v-list-item>
              <v-list-item @click="$router.push('/teacherspip')">No. of teachers on PIP</v-list-item>
              <v-list-item @click="$router.push('/teacherstbd')">No. of teachers on TBD</v-list-item>
            </v-list>
          </v-menu>
        </v-card-title>
        <div class="size-menu d-flex flex-row justify-start pr-4 w-fit align-center fixLog">
          <v-badge color="red" dot right transition="slide-x-transition" class="mr-8">
            <v-hover v-model="hover">
              <img src="../assets/bell 1.svg" />
            </v-hover>
          </v-badge>
          <div>
            <img src="../assets/avtar.png" class="rounded-xl mr-2" width="32" height="32" />
          </div>
          <div class="d-flex flex-column d-justify-evenly user-details">
            <v-card-title class="ma-0 pa-0 elipsis">
              {{ $store.state.userInfo.name }}
            </v-card-title>
            <v-card-subtitle class="accent--text ma-0 pa-0 elipsis">
              {{ $store.state.userInfo.email }}
            </v-card-subtitle>
          </div>
          <v-menu offset-y class="menuZindex">
            <template v-slot:activator="{ on, attrs }">
              <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
                <v-icon class="">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-btn @click="logout">logout</v-btn>
          </v-menu>
        </div>
      </v-card>

      <div class="pt-12">

        <div class="background fixTop30 ml-2 pt-4 pb-2">

          <v-container class="funnel-contianer pb-0 pr-7 ml-2" fluid>
            <div class="w-100 d-flex flex-row">
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg pr-0 pl-0 cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                    <p class="py-0 my-0 pl-2 funnelText">
                      TEACHERS
                    </p>
                  </v-card-title>
    
                  <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                    <span class="pl-3 black--text"
                      >Registered/Invited</span
                    >
                    <FunnelChartCommon :invites="funnelTeachersData" scaleMax="invited" propertyBegin="invited" propertyEnd="mainsStarted" funnelColor="#E0ED4B"/>
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            funnelTeachersData.invited -
                              funnelTeachersData.mainsStarted,
                            funnelTeachersData.invited
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg pr-0 pl-0 cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                    <p class="py-0 my-0 pl-2 funnelText">
                      MAINS
                    </p>
                  </v-card-title>
    
                  <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                    <span class="pl-3 black--text"
                      >Attempted</span
                    >
                    <FunnelChartCommon :invites="funnelTeachersData" scaleMax="invited" propertyBegin="mainsStarted" propertyEnd="mainsPassed" funnelColor="#6AD644"/>
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            funnelTeachersData.mainsStarted -
                              funnelTeachersData.mainsPassed,
                            funnelTeachersData.mainsStarted
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg pr-0 pl-0 cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                    <p class="py-0 my-0 pl-2 funnelText">
                      MAINS RESULT
                    </p>
                  </v-card-title>
    
                  <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                    <span class="pl-3 green--text"
                      >Passed</span
                    >
                    <FunnelChartCommon :invites="funnelTeachersData" scaleMax="invited" propertyBegin="mainsPassed" propertyEnd="mainsPassed"/>
                    <!-- <div class="d-flex justify-end py-1 pr-2">
                      <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            funnelTeachersData.mainsPassed -
                              funnelTeachersData.mainsStarted,
                            funnelTeachersData.mainsPassed
                          )
                        }}%
                      </div>
                    </div> -->
                  </v-card-text>
                </v-card>
              </div>
            </div>
          </v-container>  

          <v-row class="d-flex justify-center pt-0 my-0 ml-3 pr-1" fluid>
            <v-col cols="4" md="4" sm="4" class="pl-1">
              <div class="text-h6 font-weight-bold highEmphasis">
               Top Ranked Teachers 
              </div>
            </v-col>

            <v-col cols="8" md="8" sm="8" class="d-flex flex-row justify-end">
              <v-card class="elevation-0 ma-0 pa-0 transparent">
                <v-text-field solo hide-details label="Search here..." prepend-inner-icon="mdi-magnify" v-model="search"
                  class="search-bar border-button rounded-pill mx-3" dense clearable></v-text-field>
              </v-card>

              <v-btn flat depressed rounded outlined @click="showfilterdialogue" 
              :disabled="appraisedTeachersCount==0" 
              class="border-button mx-3"><v-icon>mdi-tune</v-icon>
              <span v-if="filterCount > 0">FILTER ({{ filterCount }})</span>
              <span v-else>FILTER</span></v-btn>
             
              <v-btn depressed outlined rounded @click="downloadTeachersToBeAppraised"  :disabled="topTeacherInSchool.length==0" class="border-button mx-3" ><v-icon>mdi-import
                  mdi-rotate-90</v-icon></v-btn>
            </v-col>
          </v-row>

        <v-card
        elevation="0"
        height="55px"
        class="d-flex align-center rounded-table mx-5"
        color="#B2AC88"
      >
        <v-row class="pl-4">
          <v-col cols="1" class="reportTableText"> S.No </v-col>
          <v-col cols="2" class="reportTableText mr-8"> Full Name </v-col>
          <v-col cols="2" class="reportTableText mr-8"> Campaign Name </v-col>
          <v-col cols="2" class="reportTableText"> School Name </v-col>
          <v-col cols="1" class="reportTableText"> Mains Score</v-col>
          <v-col cols="2" class="text-center reportTableText"> League </v-col>
          <v-col cols="1" class="text-end reportTableText"> Action </v-col>

        </v-row>
      </v-card>
    </div>
      <v-card
          class="rounded-table mx-5 mt-2"
          id="myScroll"
          height="350px"
          elevation="0"
        >
          <v-list elevation="0" class="px-0 py-0">
            <v-list-item
              class="px-0 pl-4 py-0"
              v-for="(item, index) in displayedTeachers"
              :key="index"
            >
              <v-row class="px-0 py-0 mt-2">
                <v-col cols="1" class="d-flex flex-row align-center ml-2 py-0">
                  {{ index + 1 }}
                </v-col>
                <v-col
                  class="d-flex flex-row align-center pl-0 mr-8 py-0"
                  cols="2"
                >
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <td class="t-data" v-bind="attrs" v-on="on">
                        {{ `${item.firstName} ${item.lastName}` }}
                      </td>
                    </template>
                    <span style="font-size: 15px">
                      {{
                        "Grade : " +
                        item.responsibleTeachingAt[0].grade +
                        ", " +
                        "Level : " +
                        item.responsibleTeachingAt[0].level +
                        ", " +
                        "Subject : " +
                        item.responsibleTeachingAt[0].subject
                      }}
                    </span>
                  </v-tooltip>
                </v-col>
                <v-col cols="2" class="d-flex flex-row align-center px-0 py-0">
                  <div>{{ item.campaignName }}</div>
                </v-col>
                <v-col cols="2" class="d-flex flex-row align-center px-0 py-0">
                  <div>{{ item.schoolName }}</div>
                </v-col>
                <v-col cols="1" class="d-flex flex-row align-center px-0 py-0">
                  <div>{{item.netScore}}</div>
                </v-col>
                <v-col cols="2">
                  <div
                    v-if="item.coreSkillsSubject != undefined && item.coreSkillsSubject.length > 0"
                    class="text-subtitle-1 d-flex justify-center"
                  >
                    <div
                      v-if="item.coreSkillsSubject.length > 0 && item.coreSkillsSubject.length <= 3"
                      class="d-flex flex-row w-100 flex-wrap d-flex justify-center"
                    >
                      <div
                        v-for="(coreSkill, index) in item.coreSkillsSubject"
                        :key="index + coreSkill.skillName"
                        class="w-fit"
                      >
                        <v-tooltip v-if="coreSkill.leagueValueIcon != 'NA'" top>
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              v-bind="attrs"
                              v-on="on"
                              class="d-flex flex-column align-center mr-6"
                            >
                              <v-icon
                                v-if="coreSkill.league == 'Gold'"
                                height="23px"
                                width="25"
                                class="goldIcons--text"
                              >
                                mdi-shield-crown-outline
                              </v-icon>
                              <v-icon
                                v-if="coreSkill.league == 'Silver'"
                                height="23px"
                                width="25"
                                class="silverIcon--text"
                              >
                                mdi-shield-crown-outline
                              </v-icon>
                              <v-icon
                                v-if="coreSkill.league == 'Bronze' || coreSkill.league == 'Bonze'"
                                height="23px"
                                width="25"
                                class="bronzeIcon--text"
                              >
                                mdi-shield-crown-outline
                              </v-icon>
                            </div>
                          </template>
                          <span style="font-size: 15px">
                            {{ coreSkill.skillName }} - {{ coreSkill.percentile.toFixed(2) }}%
                          </span>
                        </v-tooltip>
                        <div v-else class="d-flex justify-center mr-8 mt-1">-</div>
                      </div>
                    </div>
                    
                    <!-- For more than 3 core skills -->
                    <div
                      v-if="item.coreSkillsSubject.length > 3"
                      class="d-flex flex-row w-100 flex-wrap"
                    >
                      <div
                        v-for="(coreSkill, index) in item.coreSkillsSubject.slice(0, 3)"
                        :key="index + coreSkill.skillName"
                      >
                        <v-tooltip v-if="coreSkill.leagueValueIcon != 'NA'" top>
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              v-bind="attrs"
                              v-on="on"
                              class="d-flex flex-column align-center ml-6"
                            >
                              <v-icon
                                v-if="coreSkill.league == 'Gold'"
                                height="23px"
                                width="25"
                                class="goldIcons--text"
                              >
                                mdi-shield-crown-outline
                              </v-icon>
                              <v-icon
                                v-if="coreSkill.league == 'Silver'"
                                height="23px"
                                width="25"
                                class="silverIcon--text"
                              >
                                mdi-shield-crown-outline
                              </v-icon>
                              <v-icon
                                v-if="coreSkill.league == 'Bronze' || coreSkill.league == 'Bonze'"
                                height="23px"
                                width="25"
                                class="bronzeIcon--text"
                              >
                                mdi-shield-crown-outline
                              </v-icon>
                              <div style="font-size: 12px">#{{ coreSkill.rank }}</div>
                            </div>
                          </template>
                          <span style="font-size: 15px">
                            {{ coreSkill.skillName }} - {{ coreSkill.percentile.toFixed(2) }}%
                          </span>
                        </v-tooltip>
                      </div>
                    </div>
                  </div>

                  <div
                    class="d-flex justify-center mr-8 mt-1"
                    v-if="
                      item.coreSkillsSubject != undefined &&
                      item.coreSkillsSubject.length == 0
                    "
                  >
                    -
                  </div>
                </v-col>
                <v-col cols="1" class="d-flex justify-end" align-self="end">
                  <img
                    @click="getResult(item)"
                    class="cursor mr-3 mb-2"
                    width="24px"
                    src="../assets/Live.svg"
                  />
                  <img
                    @click="getFinalResult(item)"
                    class="cursor  mb-2"
                    width="24px"
                    src="../assets/Final.svg"
                  />

                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
        </v-card>


      </div>

    </v-col>
  </div>
</template>
  
<script >
import Papa from "papaparse";
import AuthService from "@/services/AuthService";
import CampaignController from "@/controllers/CampaignController";
import LeagueController from "@/controllers/LeagueController";
import {
  collection,
  getDocs,
  onSnapshot,
  doc,
  query, 
  orderBy, 
  limit
} from "firebase/firestore";
import { db } from "../firebase";

import FunnelChartCommon from "./FunnelChartCommon.vue";
import { mapGetters } from 'vuex'

export default {
  name: "CampaignResult",
  components: {
   FunnelChartCommon,
  },
  data() {
    return {
      data: [
        {
          name: "Frozen Yogurt",
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
        },
        {
          name: "Eclair",
          calories: 262,
          fat: 16.0,
          carbs: 23,
          protein: 6.0,
        },
        {
          name: "Cupcake",
          calories: 305,
          fat: 3.7,
          carbs: 67,
          protein: 4.3,
        },

        {
          name: "Honeycomb",
          calories: 408,
          fat: 3.2,
          carbs: 87,
          protein: 6.5,
        },
      ],
      clusters: [],
      schoolTeacher: [],
      topTeacherInSchool: [],
      campaignNameAdmin: [],
      invited: 0,
      screeningStarted: 0,
      screeningPassed: 0,
      screeningFailed: 0,
      mainsStarted: 0,
      mainsPassed: 0,
      mainsFailed: 0,
      result: 0,
      apraisals: 0,
      pip: 0,
      tbd: 0,
      chartData: {},
      emptyChartData: {
        invited: 0,
        screeningStarted: 0,
        screeningPassed: 0,
        screeningFailed: 0,
        mainsStarted: 0,
        mainsPassed: 0,
        mainsFailed: 0
      },

      funnelTeachersData:{
        invited:0,
        screeningStarted:0,
        screeningPassed:0,
        screeningFailed:0,
        mainsStarted:0,
        mainsPassed:0,
        mainsFailed:0,
        demoSubmitted:0,
        interviewSubmitted:0,
      },
      hover:"",
      pipTeachersCount: 0,
      appraisedTeachersCount: 0,
      otherTeachersCount: 0,
      tbdTeachersCount: 0,
      
      filterDialog: false,
      filterCount: 0,
      appliedFilter: false,
      selectedStageFilter : [],
      selectedLeagueFilter : [],
      filteredTeachers: [],
      stageOptions:["Invited","Screening","Mains","Demo"],
      leagueOptions:[],

      search: "",
      searchedResults:[],
      appliedSearch:false,
      limit:null
    };
  },
  computed: {
    ...mapGetters({
      userRoleId: 'getRoleId'
    }),
    displayedTeachers() {
      if(this.appliedFilter==false){
        if(this.appliedSearch==true){
          return this.searchedResults
        }
        else{

          return this.topTeacherInSchool;
        }
        }
      else if(this.appliedFilter==true){
        if(this.appliedSearch==true){
          return this.searchedResults
        }
        else{
          return this.filteredTeachers;
        }
      }
    },
  },

  watch:{
    search(newValue){
      if (newValue=='' || newValue == null) {
        this.appliedSearch=false;
      }
      else{
        this.searchData(newValue);
      }
    },
  },

  methods: {
    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },
    
    async getLeague()
    { 
      const response = await LeagueController.getLeagues();
      if (response.status==200){
        for (const league of response.data.leagues){
          if (league.name=="Bonze" || league.name=="Bronze"){
          this.leagueOptions.push("Bronze");
          }
          else{
            this.leagueOptions.push(league.name);
          }
        }
      }
    },

    searchData(search){
      if (this.appliedFilter==true){
        this.searchedResults=this.filteredTeachers.filter((teacher)=>
          new RegExp(search,"i").test(teacher.firstName) || 
          new RegExp(search,"i").test(teacher.lastName) || 
          new RegExp(search,"i").test(`${teacher.firstName} ${teacher.lastName}`)
      );
      }
      else{
        this.searchedResults=this.topTeacherInSchool.filter((teacher)=>
          new RegExp(search,"i").test(teacher.firstName) || 
          new RegExp(search,"i").test(teacher.lastName) || 
          new RegExp(search,"i").test(`${teacher.firstName} ${teacher.lastName}`))
      }
        this.appliedSearch=true;
    },

    showfilterdialogue() {
      this.filterDialog = true;
    },

    closeFilter(){
    this.filterDialog = false;
  },
  filterBasedOnStage(selectedStage){
    console.log('filteredTeachers',this.filteredTeachers)
     let lastStatus = '';
     lastStatus = selectedStage.slice(-1)[0]?.toLowerCase();
       this.filteredTeachers =   this.filteredTeachers.filter((item)=> item.campaignStatus.map(({status})=>status?.toLowerCase()).join("").includes(lastStatus))

  },
  filterBasedOnLeague(selectedLeague){
    console.log('filteredTeachers',this.filteredTeachers)
   this.filteredTeachers =  this.filteredTeachers.filter((teacher)=>{
      let teachersLeagueArr = teacher.coreSkillsSubject.map(({league})=> league.toLowerCase())
      return selectedLeague.some((league)=> teachersLeagueArr?.includes(league.toLowerCase()) )
        })
  },
  filterTeachers(
      selectedStage =[],
      selectedLeague =[]
    ) {
      let stageApplied = selectedStage?.length > 0
      let leagueApplied =  selectedLeague?.length > 0
      this.filteredTeachers = this.topTeacherInSchool 
     
      if(stageApplied){  
       this.filterBasedOnStage(selectedStage);
      } 

      if(leagueApplied){
        this.filterBasedOnLeague(selectedLeague,);
      }
    
      this.appliedFilter=true;
      this.filterDialog=false;
    },

    clearFilter() {
      this.appliedFilter = false;
      this.selectedStageFilter = "",
      this.selectedLeagueFilter= "",
      this.filterCount = 0,
      this.filteredTeachers=[];
      // this.filterDialog = false;
    },

    getFilterCount() {
      this.filterCount = 0;
      if (this.selectedStageFilter.length > 0) {
        this.filterCount++;
      }
      if (this.selectedLeagueFilter.length > 0) {
        this.filterCount++;
      }
    },

    downloadTeachersToBeAppraised() {
        const fileName = "TeachersToBeAppraised.csv";
        const dataForDownload = [];
        for (const teacher of this.topTeacherInSchool) {
          const fullName = `${teacher.firstName} ${teacher.lastName}`;
          const campaign = teacher.campaignName;
          const schoolName = teacher.schoolName;
          const campstat = teacher.demoStatus?teacher.demoStatus:teacher.campaignStatus[teacher.campaignStatus.length-1].status

  
          dataForDownload.push({
              FullName : fullName,
              Campaign : campaign,
              SchoolName : schoolName,
              CurrentCampaignStatus : campstat
            });
          }
          this.downloadCSV(dataForDownload,fileName);
    },

    async downloadCSV(csvData, fileName) {
      const csv = Papa.unparse(csvData);

      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, fileName);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },

    async newTeacherFunnelChartDataListener(){
      const dashboardTeachersRef=doc(db, "dashboards", this.$store.state.role.id+"-vgos-teachers-dashboard", "results", "campaignresults")

      onSnapshot(dashboardTeachersRef, (doc)=>{
        const resultData=doc.data();
        if (resultData){
          console.log("resultData===>",resultData);
          this.funnelTeachersData={
            invited: resultData.invited ? resultData.invited : 0,
            screeningStarted: resultData.screeningStarted ? resultData.screeningStarted : 0,
            screeningPassed: resultData.screeningPassed ? resultData.screeningPassed : 0,
            screeningFailed: resultData.screeningFailed ? resultData.screeningFailed : 0,
            mainsStarted: resultData.mainsStarted ? resultData.mainsStarted : 0,
            mainsPassed: resultData.mainsPassed ? resultData.mainsPassed : 0,
            mainsFailed: resultData.mainsFailed ? resultData.mainsFailed : 0,
            demoSubmitted: resultData.demoSubmitted ? resultData.demoSubmitted : 0,
            interviewSubmitted:  resultData.interviewSubmitted ? resultData.interviewSubmitted : 0,
          }
        }
      })
    },
    
    async setupSnapshotListenerFunnel() {
      const id = collection(
        db,
        "dashboards",
        "management-dashboard-001",
        "campaigns"
      );
      if (id === undefined) {
        this.chartData = this.emptyChartData
      }
      const data = await getDocs(id);
      if (data === undefined) {
        this.chartData = this.emptyChartData
      }
      const document = data.docs[0]
      if (document === undefined) {
        this.chartData = this.emptyChartData
      } else {
        const docRef = doc(id, data.docs[0].id);

        onSnapshot(docRef, (snapshot) => {
          let funnelData = snapshot.data();
          if (
            funnelData.invited === undefined ||
            funnelData.screeningStarted === undefined ||
            funnelData.screeningPassed === undefined ||
            funnelData.screeningFailed === undefined ||
            funnelData.mainsStarted === undefined ||
            funnelData.mainsPassed === undefined ||
            funnelData.mainsFailed === undefined
          ) {
            this.chartData = this.emptyChartData;
          } else {
            this.chartData = funnelData;
            this.invited = funnelData.invited;
            this.screeningStarted = funnelData.screeningStarted;
            this.screeningPassed = funnelData.screeningPassed;
            this.mainsStarted = funnelData.mainsStarted;
            this.mainsPassed = funnelData.mainsPassed;
            this.screeningFailed = funnelData.screeningFailed;
            this.mainsFailed = funnelData.mainsFailed;
            this.apraisals = funnelData.mainsPassed;
            this.pip = funnelData.mainsFailed;
            this.tbd = funnelData.screeningFailed;

          }
        });
      }
    },

    calculatePercentage(value, total) {
      if (total === 0) {
        return 0;
      }
      const percentage = (value / total) * 100;
      if (isNaN(percentage)) {
        return 0;
      }
      const roundedPercentage = Math.round(percentage);
      return roundedPercentage;
    },

    async teacherSnapshotListener() {
      const campaignRef = collection(db, "campaigns"); // const schoolMarks = {}

      this.appraisedTeachersCount=0;
      this.pipTeachersCount=0;
      this.tbdTeachersCount=0;
      this.otherTeachersCount=0;

      onSnapshot(campaignRef, (snapshot) => {
        this.schoolTeacher.splice(0)

        snapshot.docs.forEach(async (doc) => {
          const campaignName = doc.data().name ? doc.data().name : "";
          if (doc.data().audienceType.toLowerCase() === "teacher") {
            try {
              const jobSeekerRef = collection(campaignRef, doc.id, "audience");
              const jobSeekDocs = await getDocs(jobSeekerRef);
              jobSeekDocs.forEach((d) => {
                const teachersData=d.data();
                
                if(teachersData.campaignStatus.some(status => status.status === "MAINS PASSED") && teachersData?.coreSkillsSubject?.length > 0)
                {
                  let flag=true;
                  for (const dt of teachersData.coreSkillsSubject) {
                    // if (dt.league !== "Not Eligible") {
                      // Here we need to fetch the SCREENING and MAINS sub-collections
                      try {
                        let mainsTotal = 0;
                        let mainsNetScore = 0;

                        // Calculate the total scores for screening

                        teachersData.coreSkillsSubject.forEach((teacherMainsData) => {
                          // // const teacherMainsData = mainsDoc.data();
                          mainsTotal += parseFloat(teacherMainsData?.finalNetScore);
                        });

                        mainsTotal = mainsTotal/teachersData.coreSkillsSubject.length;
                        mainsNetScore = (mainsTotal) ? mainsTotal.toFixed(2) : 0.00;

                        // Prepare the job seeker data for display
                        const temp = {
                          firstName: teachersData?.personalInfo?.firstName || "",
                          lastName: teachersData?.personalInfo?.lastName || "",
                          campaignName: campaignName,
                          schoolName: Array.isArray(teachersData?.schoolName) ? teachersData?.schoolName[0] : teachersData?.schoolName,
                          mainsTotal: mainsNetScore,
                          status: teachersData.campaignStatus ? teachersData.campaignStatus[teachersData.campaignStatus.length - 1].status : "INVITED",
                          responsibleTeachingAt: teachersData.responsibleTeachingAt ? teachersData.responsibleTeachingAt : [[]],

                          stage: "INVITED",
                          campaignId: doc.id,
                          userId: teachersData.userId,
                          campaignStatus : teachersData.campaignStatus,
                          coreSkillsSubject : teachersData.coreSkillsSubject,
                          netScore: mainsNetScore
                        };

                        // Determine the stage based on status
                        if (temp.status === "MAINS PASSED" || temp.status === "MAINS FAILED")
                          temp.stage = "Mains";

                        this.schoolTeacher.push(temp);
                        break;
                      } catch (error) {
                        console.error("Error fetching screening or mains documents:", error);
                      }
                    // }
                  }
                  console.log("this.schoolTeacher======>",this.schoolTeacher);
                }
              });
              // Sort and limit to top 10 based on netScore
              this.schoolTeacher.sort((p1, p2) => p2.netScore - p1.netScore);
              
              
              this.topTeacherInSchool = this.calculateUsersForLeague(this.schoolTeacher);
              
              this.topTeacherInSchool = this.addDemoInterviewScore(this.topTeacherInSchool);
              
              this.topTeacherInSchool = this.createStepperData(this.topTeacherInSchool);
              
              this.topTeacherInSchool = this.topTeacherInSchool.slice(0, this.limit);
            } catch (error) {
              console.error("Error fetching audience collection:", error);
            }
          }
        });
      });
    },
    getPassFailGenericVal(status){
      if(status !== null && status !== undefined && status !== ''){
        return status.split(' ')[1]?.toLowerCase().trim()
      } else{   return ''}
  
    },
  getStepperIconAndColor(prevStatus = '', curStatus = ''){
      let key = prevStatus+curStatus.trim()
      let map = new Map([
        ['started', {name:'mdi-dots-horizontal-circle-outline', iconColor:'blue' }],
        ['startedfailed', {name:'mdi-close-circle', iconColor: 'red'} ],
        ['startedpassed', {name: 'mdi-check-circle', iconColor: 'green'}],
        ['failed',{name:'mdi-circle-double', iconColor: 'grey'}],
        ['failedpassed', {name: 'mdi-check-circle', iconColor: 'green'}],
        ['failedstarted', {name:'mdi-dots-horizontal-circle-outline', iconColor:'blue' }],
        ['passed', {name:'mdi-circle-double', iconColor: 'grey'}],
        ['passedfailed', {name:'mdi-close-circle', iconColor: 'red'} ],
        ['passedstarted', {name:'mdi-dots-horizontal-circle-outline', iconColor:'blue' }],
        ['', {name:'mdi-circle-double', iconColor: 'grey'}],

      ])
      return map.get(key)
    },
    createStepperData(data){
     return data?.map((item)=>{
        let obj = {invitation: { name:'mdi-check-circle', iconColor: 'green'}, 
                 screening:{  },
                 mains:{ },
                 demo:{ },
                 interview:{}}
        let status = item?.campaignStatus;
        // obj['screening'] = this.getStepperIconAndColor(this.getPassFailGenericVal(status[1]?.status), this.getPassFailGenericVal(status[2]?.status))
        obj['mains'] = this.getStepperIconAndColor(this.getPassFailGenericVal(status[1]?.status), this.getPassFailGenericVal(status[2]?.status))
        // obj['demo'] = this.getStepperIconAndColor(this.getPassFailGenericVal(status[5]?.status), this.getPassFailGenericVal(status[6]?.status))
        // obj['interview'] = this.getStepperIconAndColor(this.getPassFailGenericVal(status[7]?.status), this.getPassFailGenericVal(status[8]?.status))
        item['stepper'] = obj;
        obj = {};
        return item;
      })
    },
    addDemoInterviewScore(data){
  return data.map((user)=>{
        if(user?.demoStatus){
          if(user.userType !== 'Teacher' && user.campaignStatus[user.campaignStatus.length-1].status == 'MAINS PASSED') {
            user.enableScheduleInterview = true
          }
          if(user.campaignStatus.filter((item)=> item.status?.includes('DEMO')).length == 0){
            user.campaignStatus.push({status: 'DEMO STARTED'})
           user.campaignStatus.push({status: 'DEMO PASSED'}) 
          }
                  
        }
        if(user?.interViewFeedback?.length > 0){
          if(user.campaignStatus.filter((item)=> item.status?.includes('INTERVIEW')).length == 0){
            user.campaignStatus.push({status: 'INTERVIEW STARTED'})
           user.campaignStatus.push({status: 'INTERVIEW PASSED'})
          }       
        }
        return user
      })
    
    },
    calculateUsersForLeague(users) {
      users.forEach((user) => {
        var coreSkills = [];
        if (user.coreSkillsSubject !== undefined) {
          user.coreSkillsSubject.forEach((coreSkillSubject) => {
            if (coreSkillSubject.result == "PASSED") {
              coreSkills.push(coreSkillSubject);
            }
          });
        }
        user.coreSkillsSubject = coreSkills;
      });
      return users;
    },
    async getResult(data) {
      const response = await CampaignController.getResult(data.userId, data.campaignId);
      if (response.status == 200) {
        if (response.data.length == 0) {
          alert("User test not started");
        } else {
          this.$router.push(`/testreport/${data.campaignId}/${data.userId}`);
        }
      } else {
        alert(response.data.error);
      }
    },
    getFinalResult(data) {
      this.$router.push({
        path: "/userreport",
        name: "UserReport", //use name for router push
        query: {
          campaignId: data.campaignId,
          userId: data.userId,
        },
      });
    },

  //  async getTopTeachers() {
  //     try {
  //       const topTeachersData= await CampaignController.getTopTeachers(this.limit);
  //       const totalTeachers = topTeachersData.length;
  //       this.funnelTeachersData = {
  //         invited:  totalTeachers,
  //         mainsStarted: totalTeachers,
  //         mainsPassed: totalTeachers,
  //         mainsFailed: 0,
  //       }
  //       this.appraisedTeachersCount=0;
  //       this.pipTeachersCount=0;
  //       this.tbdTeachersCount=0;
  //       this.otherTeachersCount=0;
        
  //         topTeachersData.forEach((teachersData) => {
  //             const temp={
  //               firstName: teachersData?.personalInfo?.firstName ? teachersData.personalInfo.firstName : "",
  //               lastName: teachersData?.personalInfo?.lastName ? teachersData.personalInfo.lastName : "",
  //               // campaignName: campaignName,
  //               schoolName: teachersData?.schoolName ? teachersData.schoolName : "",
  //               // screeningTotal: teachersData.screeningTotal ? teachersData.screeningTotal : 0,
  //               mainsTotal: teachersData.mainsTotal ? teachersData.mainsTotal : 0,
  //               status: teachersData.campaignStatus ? teachersData.campaignStatus[teachersData.campaignStatus.length-1].status : "INVITED",
  //               responsibleTeachingAt: teachersData.responsibleTeachingAt ? teachersData.responsibleTeachingAt : [[]],
  //               // stage: "INVITED",
  //               campaignStatus : teachersData.campaignStatus,
  //               coreSkillsSubject : teachersData.coreSkillsSubject,
  //               userId: teachersData.userId,
  //               netScore: teachersData.netScore,
  //               campaignId: teachersData.campaignId,
  //             }
              
  //             if (temp.status==="MAINS PASSED" || temp.status==="MAINS FAILED")
  //               temp.stage="Mains"
  //             this.schoolTeacher.push(temp);
  //             this.appraisedTeachersCount++;                      
  //           })
  
  //     this.schoolTeacher.sort((p1, p2) => p2.netScore - p1.netScore);
  //     this.topTeacherInSchool = this.calculateUsersForLeague(this.schoolTeacher);
  //     this.topTeacherInSchool = this.addDemoInterviewScore(this.topTeacherInSchool)
  //     this.topTeacherInSchool = this.createStepperData(this.topTeacherInSchool)
  //     } catch (error) {
  //       console.error("Error fetching audience collection:", error);
  //                     }
  //           }
  },

  created() {
    this.limit = this.$route.params.id;
    console.log(this.userRoleId)
    this.teacherSnapshotListener();
    this.setupSnapshotListenerFunnel()
    this.newTeacherFunnelChartDataListener();
    this.getLeague();
    // this.getTopTeachers();
  },

};
</script>
  
<style scoped>
.tbl>.t-head>.t-row>.head {
  background-color: primary;
}

.tbl {
  height: 196px;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}

.tbl th {
  padding: 8px;
}

.tbl td {
  padding: 5px;
}

.tbl>.t-body>.t-row {
  background-color: white;
}

.head {
  text-align: center;
}

.tbl>.t-body>.t-row>.t-data {
  text-align: center;
}

.tbl>tr:nth-child(even) {
  background-color: white !important;
}
</style>