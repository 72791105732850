<template>
  <div>
    <v-col>
      <v-card height="64px" width="100%"
        class="ml-4 pl-1 pt-5 background elevation-0 d-flex flex-row justify-space-between align-center fixBar">
        <v-card-title class="pl-0 ml-0">
          <v-span @click="$router.push('/')"
            class="breadcrumb-text-unselected underline-on-hover cursor">Dashboard</v-span>

          <v-span><v-icon class="breadcrumb-arrow-unselected">mdi-chevron-right</v-icon></v-span>
          <v-menu offset-y class="menuZindex">
            <template v-slot:activator="{ on }">
              <v-span v-on="on" class="breadcrumb-text">
                Campaign Result
                <v-icon class="breadcrumb-arrow cursor">mdi-chevron-down</v-icon>
              </v-span>
            </template>

            <v-list>
              <v-list-item @click="$router.push('/clusterjobseekersappraisal')">No. of users recommended</v-list-item>
              <v-list-item @click="$router.push('/clusterjobseekerspip')">No. of users rejected</v-list-item>
              <v-list-item @click="$router.push('/clusterjobseekerstbd')">No. of teachers on TBD</v-list-item>
            </v-list>
          </v-menu>
        </v-card-title>
        <div class="size-menu d-flex flex-row justify-start w-fit align-center fixLog">
          <div>
            <img src="../../assets/avtar.png" class="rounded-xl mr-2" width="24px" />
          </div>
          <div class="d-flex flex-column d-justify-evenly user-details">
            <v-card-title class="ma-0 pa-0 elipsis">
              {{ $store.state.userInfo.name }}
            </v-card-title>
            <v-card-subtitle class="accent--text ma-0 pa-0 elipsis">
              {{ $store.state.userInfo.email }}
            </v-card-subtitle>
          </div>
          <v-menu offset-y class="menuZindex">
            <template v-slot:activator="{ on, attrs }">
              <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
                <v-icon class="">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-btn @click="logout">logout</v-btn>
          </v-menu>
        </div>
      </v-card>

      <div class="pt-12">

        <div class="background fixTop30 ml-2 pt-4">

          <v-container class="funnel-contianer pr-7 ml-2" fluid>
            <div class="w-100 d-flex flex-row">
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg pr-0 pl-0 cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                    <p class="py-0 my-0 pl-2 funnelText">
                      JOB SEEKERS
                     </p>
                  </v-card-title>

                  <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                    <span class="pl-3 black--text"
                      >Users Invited</span
                    >
                    <FunnelChartCommon :invites="clusterJobSeekersChartData" scaleMax="totalJobSeekersInvited" propertyBegin="totalJobSeekersInvited" propertyEnd="totalJobSeekersScreeningStarted" funnelColor="#E0ED4B"/>
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            clusterJobSeekersChartData.totalJobSeekersInvited -
                            clusterJobSeekersChartData.totalJobSeekersScreeningStarted,
                            clusterJobSeekersChartData.totalJobSeekersInvited
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                    <p class="pl-2 funnelText">
                      SCREENING
                    </p>
                  </v-card-title>

                  <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                    <span class="pl-3 black--text"
                      >Attempted</span
                    >
                    <FunnelChartCommon :invites="clusterJobSeekersChartData" scaleMax="totalJobSeekersInvited" propertyBegin="totalJobSeekersScreeningStarted" propertyEnd="totalJobSeekersScreeningPassed" funnelColor="#C3ED4B"/>
                    <div class="d-flex justify-end py-1 pr-2">
                     <img src="../../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            clusterJobSeekersChartData.totalJobSeekersScreeningStarted -
                            clusterJobSeekersChartData.totalJobSeekersScreeningPassed,
                            clusterJobSeekersChartData.totalJobSeekersScreeningStarted
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                    <p class="pl-2 funnelText">
                      SCREENING RESULT
                    </p>
                  </v-card-title>
                  <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                    <span class="pl-3 green--text"
                      >Passed</span
                    >
                    <FunnelChartCommon :invites="clusterJobSeekersChartData" scaleMax="totalJobSeekersInvited" propertyBegin="totalJobSeekersScreeningPassed" propertyEnd="totalJobSeekersMainsStarted" funnelColor="#B0D644"/>
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            clusterJobSeekersChartData.totalJobSeekersScreeningPassed -
                            clusterJobSeekersChartData.totalJobSeekersMainsStarted,
                            clusterJobSeekersChartData.totalJobSeekersScreeningPassed
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                    <p class="pl-2 funnelText">MAINS</p>
                  </v-card-title>
                  <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                    <span class="pl-3 black--text"
                      >Attempted</span
                    >
                    <FunnelChartCommon :invites="clusterJobSeekersChartData" scaleMax="totalJobSeekersInvited" propertyBegin="totalJobSeekersMainsStarted" propertyEnd="totalJobSeekersMainsPassed" funnelColor="#6AD644"/>
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            clusterJobSeekersChartData.totalJobSeekersMainsStarted -
                            clusterJobSeekersChartData.totalJobSeekersMainsPassed,
                            clusterJobSeekersChartData.totalJobSeekersMainsStarted
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                    <p class="pl-2 funnelText">
                      MAINS RESULT
                    </p>
                  </v-card-title>

                  <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                    <span class="pl-3 green--text"
                      >Passed</span
                    >
                    <FunnelChartCommon :invites="clusterJobSeekersChartData" scaleMax="totalJobSeekersInvited" propertyBegin="totalJobSeekersMainsPassed" propertyEnd="totalJobSeekersDemoSubmitted"/>
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            clusterJobSeekersChartData.totalJobSeekersMainsPassed -
                            clusterJobSeekersChartData.totalJobSeekersDemoSubmitted,
                            clusterJobSeekersChartData.totalJobSeekersMainsPassed
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                    <p class="pl-2 funnelText">
                      DEMO
                    </p>
                  </v-card-title>

                  <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                    <span class="pl-3 green--text"
                      >Submitted</span
                    >
                    <FunnelChartCommon :invites="clusterJobSeekersChartData" scaleMax="totalJobSeekersInvited" propertyBegin="totalJobSeekersDemoSubmitted" propertyEnd="totalJobSeekersInterviewSubmitted"/>
                    <div class="d-flex justify-end py-1 pr-2">
                     <img src="../../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            clusterJobSeekersChartData.totalJobSeekersDemoSubmitted -
                            clusterJobSeekersChartData.totalJobSeekersInterviewSubmitted,
                            clusterJobSeekersChartData.totalJobSeekersDemoSubmitted
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="px-0 py-0 pl-1 w-19">
                <v-card class="cardBorder rounded pr-0 pl-0" width="100%" height="30vh">
                  <v-card-title class="pr-0 pl-0 pb-0">
                    <p class="font-weight-bold pl-2 funnelText">CAMPAIGN SUMMARY</p>
                  </v-card-title>

                  <v-card outlined class="font-weight-medium pl-2 d-flex align-center rounded mx-3 my-2" width="80%"
                    height="40px">
                    Passed : {{ this.clusterJobSeekersChartData.totalJobSeekersMainsPassed }}
                    <v-spacer></v-spacer>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-card>
                  <v-card outlined class="font-weight-medium pl-2 d-flex align-center rounded mx-3 my-2" width="80%"
                    height="40px">
                    Rejected :  {{ this.clusterJobSeekersChartData.totalJobSeekersMainsFailed }}
                    <v-spacer></v-spacer>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-card>
                  <v-card outlined class="font-weight-medium pl-2 d-flex align-center rounded mx-3 my-2" width="80%"
                    height="40px">
                    For TBD : {{ this.clusterJobSeekersChartData.totalJobSeekersScreeningFailed }}
                    <v-spacer></v-spacer>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-card>
                </v-card>
              </div>
            </div>
            <!-- <v-col cols="2.5">
                    <FunnelChart/>
                   </v-col>
                   <v-col cols="2.5">
                    <FunnelChart/>
                   </v-col>
                   <v-col cols="2.5">
                    <FunnelChart/>
                   </v-col> -->
          </v-container>

          <v-row class="d-flex justify-center pt-0 my-0 ml-2 " fluid>
            <v-col cols="4" md="4" sm="4">
              <div class="text-h6 font-weight-bold highEmphasis">
                Recommended for Hiring ({{ this.recommendedJobseekerCount }})
              </div>
            </v-col>

            <v-col cols="8" md="8" sm="8" class="d-flex flex-row justify-end">
              <v-card class="elevation-0 ma-0 pa-0 transparent">
                <v-text-field solo hide-details label="Search here..." prepend-inner-icon="mdi-magnify" v-model="search"
                  class="search-bar border-button rounded-pill mx-3" dense clearable></v-text-field>
              </v-card>

              <v-btn depressed rounded outlined class="border-button mx-3"><v-icon>mdi-tune</v-icon>FILTER</v-btn>
              <!-- <v-btn flat depressed rounded outlined class="border-button mx-3"><img src="../../../public/svgs/SORT.svg"
              alt="" />SORT</v-btn> -->

              <!-- <v-btn
                  class="primary mx-2" rounded><v-icon>mdi-export</v-icon>Export</v-btn> -->
              <v-btn depressed outlined rounded class="border-button mx-3"><v-icon>mdi-import
                  mdi-rotate-90</v-icon></v-btn>
            </v-col>
          </v-row>

        </div>

        <v-card elevation="0" class="ml-6 mr-4 mt-0">
          <v-card-title class="text-subtitle-1 pa-0">
            <table class="tbl">
              <thead class="t-head">
                <tr class="t-row">
                  <th class="head">Rank</th>
                  <th class="head">Full Name</th>
                  <th class="head">Campaign</th>
                  <th class="head">School Name</th>
                  <th class="head">Stage</th>
                  <th class="head">Status</th>
                  <th class="head">Screening</th>
                  <th class="head">Mains</th>
                </tr>
              </thead>
              <tbody class="t-body">
                <tr class="t-row" v-for="(teacher, index) in displayedClusterTeachers" :key="teacher.id">
                  <td class="t-data">{{ index + 1 }}</td>
                  <td class="t-data">
                    {{ `${teacher.firstName} ${teacher.lastName}` }}
                  </td>
                  <td class="t-data">{{ teacher.campaignName }}</td>
                  <td class="t-data">{{ teacher.schoolName }}</td>
                  <td class="t-data">
                    {{
                      teacher.stage === "Screening" ||
                      teacher.stage === "Mains"
                      ? teacher.stage
                      : "-"
                    }}
                  </td>
                  <td class="t-data">
                    <v-chip class="yetToAttempt-color" text-color="#000000DE" outlined v-if="teacher.stage === 'Screening' ||
                        teacher.stage === 'Mains'
                        ">
                      <div class="dot-yetToAttempt"></div>{{ teacher.status }}
                    </v-chip>
                    <template v-else>-</template>
                  </td>
                  <td class="t-data">
                    {{ teacher.screeningTotal }}
                  </td>
                  <td class="t-data">
                    {{ teacher.mainsTotal }}
                  </td>
                </tr>
              </tbody>
              <!-- <tbody class="t-body">
              <tr class="t-row">
                <td class="t-data">1</td>
                <td class="t-data">Rashid Ali</td>
                <td class="t-data">Campaign 1</td>
                <td class="t-data d-flex align-center justify-center">
                  <img src="../../../public/svgs/u_map-marker.svg" alt="" srcset="" />View Map
                </td>
                <td class="t-data">VGOS- Malad</td>
                <td class="t-data">Screening</td>
                <td class="t-data d-flex h-100 align-center justify-center">
                  <v-chip class="ma-1 yetToAttempt-color" text-color="#000000DE" size="x-small">
                    <div class="dot-yetToAttempt"></div>
                    Yet To Attempt
                  </v-chip>
                </td>
                <td class="t-data">68%</td>
                <td class="t-data">-</td>
              </tr>
              <tr class="t-row">
                <td class="t-data">2</td>
                <td class="t-data">Rashid Ali</td>
                <td class="t-data">Campaign 1</td>
                <td class="t-data d-flex align-center justify-center">
                  <img src="../../../public/svgs/u_map-marker.svg" alt="" srcset="" />View Map
                </td>
                <td class="t-data">VGOS- Malad</td>
                <td class="t-data">Mains</td>
                <td class="t-data d-flex h-100 align-center justify-center">
                  <v-chip class="ma-1 yetToAttempt-color" text-color="#000000DE" size="x-small">
                    <div class="dot-yetToAttempt"></div>
                    Yet To Attempt
                  </v-chip>
                </td>
                <td class="t-data">74%</td>
                <td class="t-data">74%</td>
              </tr>
              <tr class="t-row">
                <td class="t-data">3</td>
                <td class="t-data">Rashid Ali</td>
                <td class="t-data">Campaign 1</td>
                <td class="t-data d-flex align-center justify-center">
                  <img src="../../../public/svgs/u_map-marker.svg" alt="" srcset="" />View Map
                </td>
                <td class="t-data">VGOS- Malad</td>
                <td class="t-data">Mains</td>
                <td class="t-data d-flex h-100 align-center justify-center">
                  <v-chip class="ma-1 yetToAttempt-color" text-color="#000000DE" size="x-small">
                    <div class="dot-yetToAttempt"></div>
                    Yet To Attempt
                  </v-chip>
                </td>
                <td class="t-data">74%</td>
                <td class="t-data">74%</td>
              </tr>
              <tr class="t-row">
                <td class="t-data">4</td>
                <td class="t-data">Rashid Ali</td>
                <td class="t-data">Campaign 1</td>
                <td class="t-data d-flex align-center justify-center">
                  <img src="../../../public/svgs/u_map-marker.svg" alt="" srcset="" />View Map
                </td>
                <td class="t-data">VGOS- Malad</td>
                <td class="t-data">Mains</td>
                <td class="t-data d-flex h-100 align-center justify-center">
                  <v-chip class="ma-1 yetToAttempt-color" text-color="#000000DE" size="x-small">
                    <div class="dot-yetToAttempt"></div>
                    Yet To Attempt
                  </v-chip>
                </td>
                <td class="t-data">74%</td>
                <td class="t-data">74%</td>
              </tr>
              <tr class="t-row">
                <td class="t-data">5</td>
                <td class="t-data">Rashid Ali</td>
                <td class="t-data">Campaign 1</td>
                <td class="t-data d-flex align-center justify-center">
                  <img src="../../../public/svgs/u_map-marker.svg" alt="" srcset="" />View Map
                </td>
                <td class="t-data">VGOS- Malad</td>
                <td class="t-data">Mains</td>
                <td class="t-data d-flex h-100 align-center justify-center">
                  <v-chip class="ma-1 yetToAttempt-color" text-color="#000000DE" size="x-small">
                    <div class="dot-yetToAttempt"></div>
                    Yet To Attempt
                  </v-chip>
                </td>
                <td class="t-data">74%</td>
                <td class="t-data">74%</td>
              </tr>
              <tr class="t-row">
                <td class="t-data">6</td>
                <td class="t-data">Rashid Ali</td>
                <td class="t-data">Campaign 1</td>
                <td class="t-data d-flex align-center justify-center">
                  <img src="../../../public/svgs/u_map-marker.svg" alt="" srcset="" />View Map
                </td>
                <td class="t-data">VGOS- Malad</td>
                <td class="t-data">Mains</td>
                <td class="t-data d-flex h-100 align-center justify-center">
                  <v-chip class="ma-1 yetToAttempt-color" text-color="#000000DE" size="x-small">
                    <div class="dot-yetToAttempt"></div>
                    Yet To Attempt
                  </v-chip>
                </td>
                <td class="t-data">74%</td>
                <td class="t-data">74%</td>
              </tr>
            </tbody> -->
            </table>
          </v-card-title>
        </v-card>

      </div>

    </v-col>
  </div>
</template>
      
<script >

import AuthService from "@/services/AuthService";
import {
  collection,
  getDocs,
  onSnapshot,
  doc,
  collectionGroup,
  query,
  where,
  QuerySnapshot,
} from "firebase/firestore";
import { db } from "../../firebase";
import FunnelChartCommon from "../FunnelChartCommon.vue";
export default {
  name: "CampaignResult",
  components: {
    FunnelChartCommon
  },
  data() {
    return {
      data: [
        {
          name: "Frozen Yogurt",
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
        },
        {
          name: "Eclair",
          calories: 262,
          fat: 16.0,
          carbs: 23,
          protein: 6.0,
        },
        {
          name: "Cupcake",
          calories: 305,
          fat: 3.7,
          carbs: 67,
          protein: 4.3,
        },

        {
          name: "Honeycomb",
          calories: 408,
          fat: 3.2,
          carbs: 87,
          protein: 6.5,
        },
      ],
      clusters: [],
      schoolClusterTeacher: [],
      topTeacherInCluster: [],
      invited: 0,
      screeningAttempted: 0,
      screeningResult: 0,
      mainsAttempted: 0,
      result: 0,
      apraisals: 0,
      pip: 0,
      tbd: 0,
      clusterJobSeekersChartData: {},
      emptyClusterJobSeekersChartData: {
        totalJobSeekersInvited: 0,
        totalJobSeekersScreeningStarted: 0,
        totalJobSeekersScreeningPassed: 0,
        totalJobSeekersScreeningFailed: 0,
        totalJobSeekersMainsStarted: 0,
        totalJobSeekersMainsPassed: 0,
        totalJobSeekersMainsFailed: 0,
        totalJobSeekersDemoSubmitted: 0,
        totalJobSeekersInterviewSubmitted: 0,
      },
      roleCluster: "",
      roleSchool: "",
      jobSeekerList: [],
      recommendedJobseekerCount: 0,
    };
  },
  computed: {
    displayedClusterTeachers() {
      return this.topTeacherInCluster;
    },
  },
  methods: {
    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },


    async setupSnapshotListenerJobSeekersClusterFunnel() {
      const id = collection(
        db,
        "dashboards",
        "management-dashboard-001",
        "clusters"
      );
      console.log("kdjkbcsjbjc", id);
      if (!id) {
        console.log("isnide if dashboard");
        this.clusterJobSeekersChartData = this.emptyClusterJobSeekersChartData;
        console.log("isnide snap", this.clusterJobSeekersChartData);
      }
      const q = query(id, where("name", "==", this.roleCluster));
      console.log("cluster", this.roleCluster);
      console.log("ref1", q);
      // if (!q.exists) {
      //   console.log("isnide if quer@y");
      //   this.clusterTeacherChartData = this.emptyClusterTeacherChartData;
      //   console.log("isnide snap", this.schoolJobSekerChartData);
      // }
      const data = await getDocs(q);
      console.log("data current >>", data.docs[0].id);
      const schoolRef = collection(
        db,
        "dashboards",
        "management-dashboard-001",
        "clusters",
        data.docs[0].id,
        "schools"
      );
      if (!schoolRef.exists) {
        console.log("isnide if Adil");
        this.clusterJobSeekersChartData = this.emptyClusterJobSeekersChartData;
      }
      onSnapshot(schoolRef, (snapshot) => {
        this.clusterJobSeekersChartData = {
          totalJobSeekersInvited: 0,
          totalJobSeekersScreeningStarted: 0,
          totalJobSeekersScreeningPassed: 0,
          totalJobSeekersScreeningFailed: 0,
          totalJobSeekersMainsStarted: 0,
          totalJobSeekersMainsPassed: 0,
          totalJobSeekersMainsFailed: 0,
          totalJobSeekersDemoSubmitted: 0,
          totalJobSeekersInterviewSubmitted: 0,
        },
          snapshot.forEach((doc) => {
            if (
              doc.data().totalJobSeekersInvited === undefined ||
              doc.data().totalJobSeekersScreeningStarted === undefined ||
              doc.data().totalJobSeekersScreeningPassed === undefined ||
              doc.data().totalJobSeekersScreeningFailed === undefined ||
              doc.data().totalJobSeekersMainsFailed === undefined ||
              doc.data().totalJobSeekersMainsPassed === undefined ||
              doc.data().totalJobSeekersMainsStarted === undefined ||
              doc.data().totalJobSeekersDemoSubmitted === undefined ||
              doc.data().totalJobSeekersInterviewSubmitted === undefined 
            ) {
              this.clusterJobSeekersChartData = this.emptyClusterJobSeekersChartData;
              console.log("bskdbcj", this.clusterJobSeekersChartData);
            } else {
              console.log("ak##12", doc.data());
              this.clusterJobSeekersChartData.totalJobSeekersInvited +=
                doc.data().totalJobSeekersInvited;
              this.clusterJobSeekersChartData.totalJobSeekersScreeningStarted +=
                doc.data().totalJobSeekersScreeningStarted;
              this.clusterJobSeekersChartData.totalJobSeekersScreeningPassed +=
                doc.data().totalJobSeekersScreeningPassed;
              this.clusterJobSeekersChartData.totalJobSeekersScreeningFailed +=
                doc.data().totalJobSeekersScreeningFailed;
              this.clusterJobSeekersChartData.totalJobSeekersMainsStarted +=
                doc.data().totalJobSeekersMainsStarted;
              this.clusterJobSeekersChartData.totalJobSeekersMainsPassed +=
                doc.data().totalJobSeekersMainsPassed;
              this.clusterJobSeekersChartData.totalJobSeekersMainsFailed +=
                doc.data().totalJobSeekersMainsFailed;
              this.clusterJobSeekersChartData.totalJobSeekersDemoSubmitted +=
                doc.data().totalJobSeekersDemoSubmitted;
              this.clusterJobSeekersChartData.totalJobSeekersInterviewSubmitted +=
                doc.data().totalJobSeekersInterviewSubmitted;
              console.log("bhcjsbdc", this.clusterJobSeekersChartData);
            }
          });
      });
    },
    calculatePercentage(value, total) {
      if (total === 0) {
        // console.log("Error: Total cannot be zero.");
        return 0;
      }
      const percentage = (value / total) * 100;
      if (isNaN(percentage)) {
        // console.log("Error: Invalid input. Please provide valid numbers.");
        return 0;
      }
      const roundedPercentage = Math.round(percentage);
      // console.log(roundedPercentage);
      return roundedPercentage;
    },
    getClusterOrSchool() {
      this.roleCluster = this.$store.state.role.clusterName;
      this.roleSchool = this.$store.state.role.schools;

      console.log("my cluster>>", this.roleCluster);
      console.log("my school>>.....", this.roleSchool);

      // if (this.roleSchool == undefined) {
      //   AuthService.logout();
      //   this.$router.push("/login");
      // }
      // if (this.roleCluster != "All Clusters") {
      //   this.campaignData.clusters = [this.roleCluster];
      //   this.getClusterSchool();
      // }
      // if (this.roleSchool != "All") {
      //   this.campaignData.schools = [this.roleSchool];
      // }
    },
    // async jobseekerClusterSnapshotListener() {
    //   const campaignRef = collection(db, "campaigns"); // const schoolMarks = {}

    //   onSnapshot(campaignRef, (snapshot) => {
    //     let countJobSeekerCluster = 0;
    //     let countTeacherCluster = 0;
    //     snapshot.docs.forEach(async (doc) => {
    //       const campaignName = doc.data().name;
    //       // if teacher, run this
    //       // console.log("doc id >>", doc.id)
    //       if (
    //         doc.data().audienceType.toLowerCase() === "job_seeker" &&
    //         doc.data().clusters.includes(this.$store.state.role.clusterName)
    //       ) {
    //         console.log("teacher >>", doc.id);
    //         try {
    //           const jobSeekerRef = collection(campaignRef, doc.id, "audience");
    //           const jobSeekDocs = await getDocs(jobSeekerRef);

    //           jobSeekDocs.forEach((d) => {
    //             if (d.data().cluster == this.$store.state.role.clusterName) {
    //               countTeacherCluster++;
    //               //console.log("screening total >>", d.data().screeningTotal);
    //               const topTeachersClusterData = {
    //                 screeningTotal:
    //                   d.data().screeningTotal == null
    //                     ? "-"
    //                     : d.data().screeningTotal,
    //                 mainsTotal:
    //                   d.data().mainsTotal == null ? "-" : d.data().mainsTotal,
    //                 firstName:
    //                   d.data().personalInfo.firstName == null
    //                     ? ""
    //                     : d.data().personalInfo.firstName,
    //                 lastName:
    //                   d.data().personalInfo.lastName == null
    //                     ? ""
    //                     : d.data().personalInfo.lastName,
    //                 schoolName:
    //                   d.data().schoolName == null ? "" : d.data().schoolName,
    //                 lastStatus: (() => {
    //                   let stageValue = "";
    //                   let statusValue = "";

    //                   if (
    //                     d.data().campaignStatus &&
    //                     d.data().campaignStatus.length > 0
    //                   ) {
    //                     const lastStatus =
    //                       d.data().campaignStatus[
    //                         d.data().campaignStatus.length - 1
    //                       ].status;
    //                     if (lastStatus === "INVITED") {
    //                       stageValue = "Screening";
    //                       statusValue = "Yet to Attempt";
    //                     } else if (lastStatus === "SCREENING PASSED") {
    //                       stageValue = "Mains";
    //                       statusValue = "Yet to Attempt";
    //                     }
    //                   }

    //                   return {
    //                     stageValue: stageValue,
    //                     statusValue: statusValue,
    //                   };
    //                 })(),

    //                 campaignName: campaignName,
    //               };
    //               this.schoolClusterTeacher.push(topTeachersClusterData);
    //               console.log(
    //                 "screening total result show >>",
    //                 d.data().screeningTotal
    //               );
    //               //console.log("naresh sir", this.schoolClusterTeacher);
    //               // data.schoolMarks[d.data().schoolName] += d.data().screeningTotal
    //             }

    //           });
    //           //this.countTeacherCluster = countTeacherCluster;
    //           let sortedProducts = this.schoolClusterTeacher.sort((p1, p2) =>
    //             p1.screeningTotal < p2.screeningTotal
    //               ? 1
    //               : p1.screeningTotal > p2.screeningTotal
    //                 ? -1
    //                 : 0
    //           );
    //           this.topTeacherInCluster = this.schoolClusterTeacher;
    //         } catch (error) {
    //           console.error("Error fetching audience collection:", error);
    //         }
    //       }
    //     });
    //   });
    // },

    async jobseekerClusterSnapshotListener() {
      const campaignRef = collection(db, "campaigns"); // const schoolMarks = {}
      onSnapshot(campaignRef, (snapshot) => {
        this.passedJobSeekersCount=0
        this.rejectedJobSeekersCount=0
        this.tdbJobSeekersCount=0
        this.otherJobSeekersCount=0
        this.jobSeekerList.splice(0)

        snapshot.docs.forEach(async (doc) => {
          this.recommendedJobseekerCount = 0;
          const campaignName = doc.data().name;
         
          if (doc.data().audienceType.toLowerCase() === "job_seeker" || doc.data().audienceType.toLowerCase() === "jobseeker" || doc.data().audienceType.toLowerCase() === "job seeker") {
            try {
              const jobSeekerSchoolCluster = doc.data().clusters ?? [];
              const jobSeekerRef = collection(campaignRef, doc.id, "audience");
              const jobSeekDocs = await getDocs(jobSeekerRef);

              jobSeekDocs.forEach((d) => {
                const jobSeekerData=d.data();

                const schoolName = this.$store.state.role.schools;
                const clusterName = this.$store.state.role.clusterName;
                const jobSeekerSchoolName = jobSeekerData.teachingInterests?.schools ?? [];


                if (
                  (clusterName === "All Clusters" && schoolName === "All") ||
                  (clusterName !== "All Clusters" && schoolName === "All" && ((typeof jobSeekerSchoolCluster === 'string' && jobSeekerSchoolCluster === clusterName) || (Array.isArray(jobSeekerSchoolCluster) && jobSeekerSchoolCluster.includes(clusterName)))) ||
                  (clusterName !== "All Clusters" && ((typeof jobSeekerSchoolName === 'string' && jobSeekerSchoolName === schoolName) || (Array.isArray(jobSeekerSchoolName) && jobSeekerSchoolName.includes(schoolName))))
                  )
                  {
                    if (jobSeekerData?.campaignStatus?.length === 5) {
                      if (jobSeekerData.campaignStatus[2].status === "SCREENING PASSED" && jobSeekerData.campaignStatus[4].status === "MAINS FAILED") {
                        this.rejectedJobSeekersCount++;
                      }
    
                      if (jobSeekerData.campaignStatus[2].status === "SCREENING FAILED" && jobSeekerData.campaignStatus[4].status === "MAINS FAILED") {
                        this.tdbJobSeekersCount++;
                      }
    
                      if (jobSeekerData.campaignStatus[2].status === "SCREENING FAILED" && jobSeekerData.campaignStatus[4].status === "MAINS PASSED") {
                        this.otherJobSeekersCount++;
                      }
    
                      if (jobSeekerData.campaignStatus[2].status === "SCREENING PASSED" && jobSeekerData.campaignStatus[4].status === "MAINS PASSED") {
                        let flag=true;
                        if (jobSeekerData?.coreSkillsSubject?.length > 0) {
                          for (const dt of jobSeekerData.coreSkillsSubject) {
                            if (dt.league !== "Not Eligible") {
                              flag=false;
                              this.passedJobSeekersCount++;
                              break;
                            }
                          }
                        }
                        if (flag){
                          this.otherJobSeekersCount++;
                        }
                      }
                    }

                    if (
                      jobSeekerData?.campaignStatus?.length === 5 && 
                      jobSeekerData.campaignStatus[2].status === "SCREENING PASSED" && 
                      jobSeekerData.campaignStatus[4].status === "MAINS PASSED" && 
                      jobSeekerData?.coreSkillsSubject?.length > 0 && 
                      jobSeekerData?.interViewFeedback?.length > 0
                    ) {
                      let screeningTotal = 0;
                      let mainsTotal = 0;
                      let screeningNetScore = 0;
                      let mainsNetScore = 0;
                      let netScoreMains = 0;
                      let netScoreInterview = 0;
                      let netFinalRecommendationScore = 0;

                      // Calculate the total scores for screening and mains
                      jobSeekerData.coreSkillsSubjectScreening.forEach((jobSeekerScreeningData) => {
                        screeningTotal += parseFloat(jobSeekerScreeningData?.finalNetScore);
                      });
                      screeningTotal = screeningTotal / jobSeekerData.coreSkillsSubjectScreening.length;
                      screeningNetScore = screeningTotal ? screeningTotal.toFixed(2) : 0.00;

                      jobSeekerData.coreSkillsSubject.forEach((jobSeekerMainsData) => {
                        mainsTotal += parseFloat(jobSeekerMainsData?.finalNetScore);
                      });
                      mainsTotal = mainsTotal / jobSeekerData.coreSkillsSubject.length;
                      mainsNetScore = mainsTotal ? mainsTotal.toFixed(2) : 0.00;

                      // Calculate netScoreMains and netScoreInterview
                      for (const dt of jobSeekerData.coreSkillsSubject) {
                        if (dt.league !== "Not Eligible") {
                          try {
                            netScoreMains += parseFloat(dt.finalNetScore);
                          } catch (error) {
                            console.error("Error fetching mains documents:", error);
                          }
                        }
                      }

                      for (const interview of jobSeekerData.interViewFeedback) {
                        try {
                          netScoreInterview += parseFloat(interview.avgScore);
                        } catch (error) {
                          console.error("Error fetching screening or interview documents:", error);
                        }
                      }

                      netScoreMains = netScoreMains / jobSeekerData.coreSkillsSubject?.length;
                      netScoreInterview = netScoreInterview / jobSeekerData.interViewFeedback?.length;
                      netFinalRecommendationScore = (netScoreMains + netScoreInterview) / 2;

                      // Prepare the job seeker data for display
                      const temp = {
                        firstName: jobSeekerData?.personalInfo?.firstName || "",
                        lastName: jobSeekerData?.personalInfo?.lastName || "",
                        campaignName: campaignName,
                        schoolName: jobSeekerData?.teachingInterests?.schools?.length > 0 ? jobSeekerData.teachingInterests.schools[0] : "",
                        screeningTotal: screeningNetScore,
                        mainsTotal: mainsNetScore,
                        status: jobSeekerData.campaignStatus ? jobSeekerData.campaignStatus[jobSeekerData.campaignStatus.length - 1].status : "INVITED",
                        stage: "Invited",
                        campaignId: doc.id,
                        userId: jobSeekerData.userId,
                      };

                      // Determine the stage based on status
                      if (temp.status === "SCREENING PASSED" || temp.status === "SCREENING FAILED")
                        temp.stage = "Screening";
                      if (temp.status === "MAINS PASSED" || temp.status === "MAINS FAILED")
                        temp.stage = "Mains";

                      this.jobSeekerList.push(temp);

                      if (netFinalRecommendationScore >= 0) {
                        this.recommendedJobseekerCount += 1;
                      }
                    }

                  }

              })
              // this.topTeacherInCluster = this.schoolClusterTeacher;
              this.topTeacherInCluster=this.jobSeekerList
            } catch (error) {
              console.error("Error fetching audience collection:", error);
            }
          }
        });
      });
    },

  },

  created() {
    this.jobseekerClusterSnapshotListener()
    this.getClusterOrSchool()
    this.setupSnapshotListenerJobSeekersClusterFunnel()
  },
};
</script>
      
<style scoped>
.tbl>.t-head>.t-row>.head {
  background-color: primary;
}

.tbl {
  height: 196px;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}

.tbl th {
  padding: 8px;
}

.tbl td {
  padding: 5px;
}

.tbl>.t-body>.t-row {
  background-color: white;
}

.head {
  text-align: center;
}

.tbl>.t-body>.t-row>.t-data {
  text-align: center;
}

.tbl>tr:nth-child(even) {
  background-color: white !important;
}</style>