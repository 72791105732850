<template>
    <div class="card-container">
        <div class="card-content black--text" style="font-size: 12px" :class="{ flipped: showContent }">
            <p class="font-weight-bold my-2 ml-3">LIVE CAMPAIGN PER LEVEL</p>
            <v-row class="ml-1">
                <v-col class="my-1 py-0">Pre-primary - <b>{{stats.prePrimaryCount}}</b></v-col>
                <v-col class="my-1 py-0">primary - <b>{{stats.primaryCount}}</b></v-col>
            </v-row>
            <v-row class="ml-1">
                <v-col class="my-1 py-0">Secondary - <b>{{stats.secondaryCount}}</b></v-col>
                <v-col class="my-1 py-0">Senior Secondary - <b>{{stats.seniorSecondaryCount}}</b></v-col>
            </v-row>
            <v-row class="text-center">
                <v-col class="my-1 py-0">Middle School - <b>{{stats.middleSchoolCount}}</b></v-col>
            </v-row>
            <div class="card-back d-flex flex-column justify-space-between black--text" style="font-size: 12px">
                <p class="font-weight-bold mt-0 mb-3 ml-3">LIVE CAMPAIGN PER CLUSTER</p>
                <v-row class="ml-1">
                    <v-col class="my-1 py-0">Cluster1 - <b>1</b></v-col>
                    <v-col class="my-1 py-0">Cluster2 - <b>1</b></v-col>
                </v-row>
                <v-row class="ml-1">
                    <v-col class="my-1 py-0">Cluster3 - <b>1</b></v-col>
                    <v-col class="my-1 py-0">Cluster4 - <b>1</b></v-col>
                </v-row>
                <v-row class="text-center">
                    <v-col class="my-1 py-0">Cluster5 - <b>1</b></v-col>
                </v-row>
            </div>
        </div>
        <div class="link-container">
            <a class="flip-link" @click="showContent = !showContent">{{
                showContent ? "SHOW LEVEL" : "SHOW CLUSTER"
            }}</a>
        </div>
    </div>
</template>
  
<script>

export default {
    name: "FlipCard",
    props: ["stats"],
    data() {
        return {
            showContent: false,
        };
    },
};
</script>
  
<style scoped>
.card-container {
    position: relative;
    perspective: 1000px;
}

.card-content {
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.5s;
}

.card-back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    transform: rotateY(180deg);
    background-color: #ffffff;
}

.card-content.flipped {
    transform: rotateY(180deg);
}

.link-container {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0px;
}

.flip-link {
    color: #007fff;
    cursor: pointer;
    font-weight: bold;
}

.left-aligned {
    text-align: left;
    color: black;
}

.spaced {
    padding-bottom: 12px;
    /* Adjust the value as needed */
}
</style>